// container styles
.app {
  height: 100vh;
  overflow: auto;
}

.container {
  width: 60%;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.block {
  background-color: #f3f3f3;
  padding: 25px;
  border-radius: 10px;
}

.multiple {
  display: flex;
  gap: 62px;
}

// tag styles

h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 25px;
}

h2 {
  font-size: 26px;
}

ol {
  list-style-type: lower-alpha;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  th,
  td {
    border: 1px solid;
    padding: 8px;
  }
}

//  **** CUSTOM BLOCK STYLES ****  //

.guideContent {
  > div {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    // gap: 16px;
  }
}

.basicInfo {
  p {
    margin-bottom: 16px;
  }
}

.dimensions {
  > div > div > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 8px;
  }
}

.material {
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.resumeSession {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  canvas {
    margin-right: 40px;
  }
}
